/* eslint-disable @typescript-eslint/no-explicit-any */

import withAuthorizationRequired, { WithAuthorizationRequiredProps } from "./withAuthorizationRequired";

import React from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";

interface PrivateRouteProps extends WithAuthorizationRequiredProps {
    component: React.ComponentType<any>;
    [key: string]: any;
}

const PrivateRoute: React.FC<PrivateRouteProps> = (props: PrivateRouteProps) => {
    const { component, allowIfHasAnyRole, allowIfHasRole, disallowIfHasRole, ...rest } = props;

    const Component = withAuthenticationRequired(
        withAuthorizationRequired(component, {
            allowIfHasAnyRole,
            allowIfHasRole,
            disallowIfHasRole
        }),
        {
            onRedirecting: () => <p>Redirecting to authentication</p>
        }
    );

    return <Component {...rest} />;
};

export default PrivateRoute;
