import { CompositeFilterDescriptor } from "@progress/kendo-data-query";
import { DropdownItemProps } from "semantic-ui-react";
import { IntacctItemID } from "../types/types";

export const defaultSiteTitle = `FAST Admin`;

export const minDate = "1850-01-01";
export const maxDate = "2099-12-31";

export const ssnRegex = /\d{3}-\d{2}-\d{4}/;
export const sinRegex = /\d{3}-\d{3}-\d{3}/;

export const minAggAmtPhoneInterview = 250000;

export const coventryInformalEmail = "fasanoalerts@coventry.com";

export const accountingTypeOptions: DropdownItemProps[] = [
    { key: 0, value: 0, text: "Invoiced" },
    { key: 1, value: 1, text: "Prepay" },
    { key: 2, value: 2, text: "Autopay" }
];

export const casePriorityOptions: DropdownItemProps[] = [
    { key: 1, value: 1, text: "Standard" },
    { key: 2, value: 2, text: "Rush" },
    { key: 3, value: 3, text: "Expedited" }
];

export const genderOptions: DropdownItemProps[] = [
    { key: "M", value: "M", text: "Male" },
    { key: "F", value: "F", text: "Female" }
];

export const submitStatusOptions: DropdownItemProps[] = [
    { key: 1, value: 1, text: "In Process" },
    { key: 2, value: 2, text: "Back to Client" },
    { key: 3, value: 3, text: "On Hold" },
    { key: 4, value: 4, text: "On Hold For Payment" }
];

export const emptyFilter: CompositeFilterDescriptor = {
    logic: "and",
    filters: []
};

export enum CallQueueAction {
    NewTransmittal = "newTransmittal",
    CaseCreated = "caseCreated",
    TransmittalDiscontinued = "transmittalDiscontinued"
}

export const submitQueueChannel = { channel: "submitqueue", resource: "submit" };
export const caseDetailChannel = { channel: "caseDetail", resource: "case" };
export const caseUnderwritingChannel = { channel: "caseUnderwriting", resource: "case" };

export enum BusinessLines {
    LifeSettlement = 1,
    StructuredSettlement = 2,
    WorkersComp = 3,
    Annuity = 4,
    APSSummary = 5,
    Other = 6,
    LifeInsurance = 7,
    Adhoc = 8
}

export const businessLine = [
    {
        BusinessLineID: 1,
        Name: "Life Settlement"
    },
    {
        BusinessLineID: 2,
        Name: "Structured Settlement"
    },
    {
        BusinessLineID: 3,
        Name: "Workers Comp"
    },
    {
        BusinessLineID: 4,
        Name: "Annuity"
    },
    {
        BusinessLineID: 5,
        Name: "APS Summary"
    },
    {
        BusinessLineID: 6,
        Name: "Other"
    },
    {
        BusinessLineID: 7,
        Name: "Life Insurance"
    },
    {
        BusinessLineID: 8,
        Name: "Adhoc"
    }
];

export const SpecialProject = {
    Informal: 1,
    SpecialReport: 2,
    Upgrade: 3
};

export const servicerIDs = [
    {
        ServicerID: 1,
        Name: "eNoah"
    },
    {
        ServicerID: 2,
        Name: "Accenture"
    }
];

const intacctItemIDs: IntacctItemID[] = [
    {
        ItemID: "FAI1000",
        Description: "LS LE Reports"
    },
    {
        ItemID: "FAI1001",
        Description: "SS LE Reports"
    },
    {
        ItemID: "FAI1002",
        Description: "WC LE Reports"
    },
    {
        ItemID: "FAI1100",
        Description: "Case Consultations"
    },
    {
        ItemID: "FAI1101",
        Description: "Qtrly Director Fee"
    },
    {
        ItemID: "FAI1102",
        Description: "Med Dir Fee - Qtrly"
    },
    {
        ItemID: "FAI1103",
        Description: "Life Ins Consulting"
    },
    {
        ItemID: "FAI1104",
        Description: "UW Consultations"
    },
    {
        ItemID: "FAI1105",
        Description: "EKG Reviews"
    },
    {
        ItemID: "FAI1200",
        Description: "SS Licensing"
    },
    {
        ItemID: "FAI1201",
        Description: "ALEC Fee"
    },
    {
        ItemID: "FAI1300",
        Description: "Consultant Services"
    },
    {
        ItemID: "FAI1301",
        Description: "Expert Witness Fees"
    },
    {
        ItemID: "FAI1302",
        Description: "UW Support"
    },
    {
        ItemID: "FAI2000",
        Description: "Sponsorship Fees"
    },
    {
        ItemID: "FAI2001",
        Description: "Registration Fees"
    },
    {
        ItemID: "FAI22000",
        Description: "FAI Cust Deposit Transfer"
    },
    {
        ItemID: "FAI9000",
        Description: "Late Fees"
    },
    {
        ItemID: "FAIBKCHGS",
        Description: "Fasano Bank Charge Reimb to Customers"
    },
    {
        ItemID: "FAICDEP",
        Description: "Fasano Customer Deposit"
    },
    {
        ItemID: "FAIPPCHGS",
        Description: "Fasano PayPal Charges"
    },
    {
        ItemID: "FAIPPDEP",
        Description: "Fasano PayPal Deposit"
    }
];

export const intacctItemIDOptions: DropdownItemProps[] = intacctItemIDs.map((item) => {
    return {
        key: item.ItemID,
        value: item.ItemID,
        text: `${item.ItemID} - ${item.Description}`
    };
});

export const businessLineOptions: DropdownItemProps[] = businessLine.map((item) => {
    return {
        key: item.BusinessLineID,
        value: item.BusinessLineID,
        text: item.Name
    };
});

export const clientDisabledOptions: DropdownItemProps[] = [
    { key: "Outstanding Payment", value: "Outstanding Payment", text: "Outstanding Payment" },
    { key: "License Expired", value: "License Expired", text: "License Expired" },
    { key: "Other", value: "Other", text: "Other" }
];
// temporary solution to allow some users the ability to create adhoc cases for training
export const compensationTrainingUsers = [
    "amayer",
    "nschad",
    "dbrink",
    "aferris",
    "rruby",
    "bjsenatore",
    "edigian",
    "gmastrocola",
    "hhurley",
    "jiacovino",
    "mrusso",
    "okhaw",
    "omassey",
    "rconklin",
    "rfeingold",
    "shickey"
];
// the can view le calc is on the underwriter table, need to allow some non underwriters access
// don't want to make a new role for one person.
export const nonUnderwritersCanViewLECalculator = ["phhall"];

/** Unit options, not including null */
export const compensationUnitOptions: DropdownItemProps[] = [
    { key: "hour", value: 1, text: "hours" },
    { key: "minute", value: 2, text: "minutes" }
];

export const unverifiedConfidenceRankID = 0;
export const fictitiousConfidenceRankID = -50;

export const UnitedStatesCountryID = 0;
export const CanadaCountryID = 40;
export const CountryDdlList: DropdownItemProps[] = [
    { key: "US", value: 0, text: "United States" },
    { key: "AF", value: 1, text: "Afghanistan" },
    { key: "AX", value: 2, text: "Åland Islands" },
    { key: "AL", value: 3, text: "Albania" },
    { key: "DZ", value: 4, text: "Algeria" },
    { key: "AS", value: 5, text: "American Samoa" },
    { key: "AD", value: 6, text: "Andorra" },
    { key: "AO", value: 7, text: "Angola" },
    { key: "AI", value: 8, text: "Anguilla" },
    { key: "AQ", value: 9, text: "Antarctica" },
    { key: "AG", value: 10, text: "Antigua and Barbuda" },
    { key: "AR", value: 11, text: "Argentina" },
    { key: "AM", value: 12, text: "Armenia" },
    { key: "AW", value: 13, text: "Aruba" },
    { key: "AU", value: 14, text: "Australia" },
    { key: "AT", value: 15, text: "Austria" },
    { key: "AZ", value: 16, text: "Azerbaijan" },
    { key: "BS", value: 17, text: "Bahamas" },
    { key: "BH", value: 18, text: "Bahrain" },
    { key: "BD", value: 19, text: "Bangladesh" },
    { key: "BB", value: 20, text: "Barbados" },
    { key: "BY", value: 21, text: "Belarus" },
    { key: "BE", value: 22, text: "Belgium" },
    { key: "BZ", value: 23, text: "Belize" },
    { key: "BJ", value: 24, text: "Benin" },
    { key: "BM", value: 25, text: "Bermuda" },
    { key: "BT", value: 26, text: "Bhutan" },
    { key: "BO", value: 27, text: "Bolivia, Plurinational State of" },
    { key: "BQ", value: 28, text: "Bonaire, Sint Eustatius and Saba" },
    { key: "BA", value: 29, text: "Bosnia and Herzegovina" },
    { key: "BW", value: 30, text: "Botswana" },
    { key: "BV", value: 31, text: "Bouvet Island" },
    { key: "BR", value: 32, text: "Brazil" },
    { key: "IO", value: 33, text: "British Indian Ocean Territory" },
    { key: "BN", value: 34, text: "Brunei Darussalam" },
    { key: "BG", value: 35, text: "Bulgaria" },
    { key: "BF", value: 36, text: "Burkina Faso" },
    { key: "BI", value: 37, text: "Burundi" },
    { key: "KH", value: 38, text: "Cambodia" },
    { key: "CM", value: 39, text: "Cameroon" },
    { key: "CA", value: 40, text: "Canada" },
    { key: "CV", value: 41, text: "Cape Verde" },
    { key: "KY", value: 42, text: "Cayman Islands" },
    { key: "CF", value: 43, text: "Central African Republic" },
    { key: "TD", value: 44, text: "Chad" },
    { key: "CL", value: 45, text: "Chile" },
    { key: "CN", value: 46, text: "China" },
    { key: "CX", value: 47, text: "Christmas Island" },
    { key: "CC", value: 48, text: "Cocos (Keeling) Islands" },
    { key: "CO", value: 49, text: "Colombia" },
    { key: "KM", value: 50, text: "Comoros" },
    { key: "CG", value: 51, text: "Congo" },
    { key: "CD", value: 52, text: "Congo, Democratic Republic" },
    { key: "CK", value: 53, text: "Cook Islands" },
    { key: "CR", value: 54, text: "Costa Rica" },
    { key: "CI", value: 55, text: "Côte d'Ivoire" },
    { key: "HR", value: 56, text: "Croatia" },
    { key: "CU", value: 57, text: "Cuba" },
    { key: "CW", value: 58, text: "Curaçao" },
    { key: "CY", value: 59, text: "Cyprus" },
    { key: "CZ", value: 60, text: "Czech Republic" },
    { key: "DK", value: 61, text: "Denmark" },
    { key: "DJ", value: 62, text: "Djibouti" },
    { key: "DM", value: 63, text: "Dominica" },
    { key: "DO", value: 64, text: "Dominican Republic" },
    { key: "EC", value: 65, text: "Ecuador" },
    { key: "EG", value: 66, text: "Egypt" },
    { key: "SV", value: 67, text: "El Salvador" },
    { key: "GQ", value: 68, text: "Equatorial Guinea" },
    { key: "ER", value: 69, text: "Eritrea" },
    { key: "EE", value: 70, text: "Estonia" },
    { key: "ET", value: 71, text: "Ethiopia" },
    { key: "FK", value: 72, text: "Falkland Islands (Malvinas)" },
    { key: "FO", value: 73, text: "Faroe Islands" },
    { key: "FJ", value: 74, text: "Fiji" },
    { key: "FI", value: 75, text: "Finland" },
    { key: "FR", value: 76, text: "France" },
    { key: "GF", value: 77, text: "French Guiana" },
    { key: "PF", value: 78, text: "French Polynesia" },
    { key: "TF", value: 79, text: "French Southern Territories" },
    { key: "GA", value: 80, text: "Gabon" },
    { key: "GM", value: 81, text: "Gambia" },
    { key: "GE", value: 82, text: "Georgia" },
    { key: "DE", value: 83, text: "Germany" },
    { key: "GH", value: 84, text: "Ghana" },
    { key: "GI", value: 85, text: "Gibraltar" },
    { key: "GR", value: 86, text: "Greece" },
    { key: "GL", value: 87, text: "Greenland" },
    { key: "GD", value: 88, text: "Grenada" },
    { key: "GP", value: 89, text: "Guadeloupe" },
    { key: "GU", value: 90, text: "Guam" },
    { key: "GT", value: 91, text: "Guatemala" },
    { key: "GG", value: 92, text: "Guernsey" },
    { key: "GN", value: 93, text: "Guinea" },
    { key: "GW", value: 94, text: "Guinea-Bissau" },
    { key: "GY", value: 95, text: "Guyana" },
    { key: "HT", value: 96, text: "Haiti" },
    { key: "HM", value: 97, text: "Heard Island and McDonald Islands" },
    { key: "VA", value: 98, text: "Holy See (Vatican City State)" },
    { key: "HN", value: 99, text: "Honduras" },
    { key: "HK", value: 100, text: "Hong Kong" },
    { key: "HU", value: 101, text: "Hungary" },
    { key: "IS", value: 102, text: "Iceland" },
    { key: "IN", value: 103, text: "India" },
    { key: "ID", value: 104, text: "Indonesia" },
    { key: "IR", value: 105, text: "Iran, Islamic Republic of" },
    { key: "IQ", value: 106, text: "Iraq" },
    { key: "IE", value: 107, text: "Ireland" },
    { key: "IM", value: 108, text: "Isle of Man" },
    { key: "IL", value: 109, text: "Israel" },
    { key: "IT", value: 110, text: "Italy" },
    { key: "JM", value: 111, text: "Jamaica" },
    { key: "JP", value: 112, text: "Japan" },
    { key: "JE", value: 113, text: "Jersey" },
    { key: "JO", value: 114, text: "Jordan" },
    { key: "KZ", value: 115, text: "Kazakhstan" },
    { key: "KE", value: 116, text: "Kenya" },
    { key: "KI", value: 117, text: "Kiribati" },
    { key: "KP", value: 118, text: "Korea, Democratic People's Republic" },
    { key: "KR", value: 119, text: "Korea, Republic of" },
    { key: "KW", value: 120, text: "Kuwait" },
    { key: "KG", value: 121, text: "Kyrgyzstan" },
    { key: "LA", value: 122, text: "Lao People's Democratic Republic" },
    { key: "LV", value: 123, text: "Latvia" },
    { key: "LB", value: 124, text: "Lebanon" },
    { key: "LS", value: 125, text: "Lesotho" },
    { key: "LR", value: 126, text: "Liberia" },
    { key: "LY", value: 127, text: "Libya" },
    { key: "LI", value: 128, text: "Liechtenstein" },
    { key: "LT", value: 129, text: "Lithuania" },
    { key: "LU", value: 130, text: "Luxembourg" },
    { key: "MO", value: 131, text: "Macao" },
    { key: "MK", value: 132, text: "Macedonia, former Yugoslav Republic of" },
    { key: "MG", value: 133, text: "Madagascar" },
    { key: "MW", value: 134, text: "Malawi" },
    { key: "MY", value: 135, text: "Malaysia" },
    { key: "MV", value: 136, text: "Maldives" },
    { key: "ML", value: 137, text: "Mali" },
    { key: "MT", value: 138, text: "Malta" },
    { key: "MH", value: 139, text: "Marshall Islands" },
    { key: "MQ", value: 140, text: "Martinique" },
    { key: "MR", value: 141, text: "Mauritania" },
    { key: "MU", value: 142, text: "Mauritius" },
    { key: "YT", value: 143, text: "Mayotte" },
    { key: "MX", value: 144, text: "Mexico" },
    { key: "FM", value: 145, text: "Micronesia, Federated States of" },
    { key: "MD", value: 146, text: "Moldova, Republic of" },
    { key: "MC", value: 147, text: "Monaco" },
    { key: "MN", value: 148, text: "Mongolia" },
    { key: "ME", value: 149, text: "Montenegro" },
    { key: "MS", value: 150, text: "Montserrat" },
    { key: "MA", value: 151, text: "Morocco" },
    { key: "MZ", value: 152, text: "Mozambique" },
    { key: "MM", value: 153, text: "Myanmar" },
    { key: "NA", value: 154, text: "Namibia" },
    { key: "NR", value: 155, text: "Nauru" },
    { key: "NP", value: 156, text: "Nepal" },
    { key: "NL", value: 157, text: "Netherlands" },
    { key: "NC", value: 158, text: "New Caledonia" },
    { key: "NZ", value: 159, text: "New Zealand" },
    { key: "NI", value: 160, text: "Nicaragua" },
    { key: "NE", value: 161, text: "Niger" },
    { key: "NG", value: 162, text: "Nigeria" },
    { key: "NU", value: 163, text: "Niue" },
    { key: "NF", value: 164, text: "Norfolk Island" },
    { key: "MP", value: 165, text: "Northern Mariana Islands" },
    { key: "NO", value: 166, text: "Norway" },
    { key: "OM", value: 167, text: "Oman" },
    { key: "PK", value: 168, text: "Pakistan" },
    { key: "PW", value: 169, text: "Palau" },
    { key: "PS", value: 170, text: "Palestinian Territory, Occupied" },
    { key: "PA", value: 171, text: "Panama" },
    { key: "PG", value: 172, text: "Papua New Guinea" },
    { key: "PY", value: 173, text: "Paraguay" },
    { key: "PE", value: 174, text: "Peru" },
    { key: "PH", value: 175, text: "Philippines" },
    { key: "PN", value: 176, text: "Pitcairn" },
    { key: "PL", value: 177, text: "Poland" },
    { key: "PT", value: 178, text: "Portugal" },
    { key: "PR", value: 179, text: "Puerto Rico" },
    { key: "QA", value: 180, text: "Qatar" },
    { key: "RE", value: 181, text: "Réunion" },
    { key: "RO", value: 182, text: "Romania" },
    { key: "RU", value: 183, text: "Russian Federation" },
    { key: "RW", value: 184, text: "Rwanda" },
    { key: "BL", value: 185, text: "Saint Barthélemy" },
    { key: "SH", value: 186, text: "Saint Helena, Ascension and Tn da Cunha" },
    { key: "KN", value: 187, text: "Saint Kitts and Nevis" },
    { key: "LC", value: 188, text: "Saint Lucia" },
    { key: "MF", value: 189, text: "Saint Martin (French part)" },
    { key: "PM", value: 190, text: "Saint Pierre and Miquelon" },
    { key: "VC", value: 191, text: "Saint Vincent and the Grenadines" },
    { key: "WS", value: 192, text: "Samoa" },
    { key: "SM", value: 193, text: "San Marino" },
    { key: "ST", value: 194, text: "Sao Tome and Principe" },
    { key: "SA", value: 195, text: "Saudi Arabia" },
    { key: "SN", value: 196, text: "Senegal" },
    { key: "RS", value: 197, text: "Serbia" },
    { key: "SC", value: 198, text: "Seychelles" },
    { key: "SL", value: 199, text: "Sierra Leone" },
    { key: "SG", value: 200, text: "Singapore" },
    { key: "SX", value: 201, text: "Sint Maarten (Dutch part)" },
    { key: "SK", value: 202, text: "Slovakia" },
    { key: "SI", value: 203, text: "Slovenia" },
    { key: "SB", value: 204, text: "Solomon Islands" },
    { key: "SO", value: 205, text: "Somalia" },
    { key: "ZA", value: 206, text: "South Africa" },
    { key: "GS", value: 207, text: "South Georgia and the S. Sandwich Isls" },
    { key: "SS", value: 208, text: "South Sudan" },
    { key: "ES", value: 209, text: "Spain" },
    { key: "LK", value: 210, text: "Sri Lanka" },
    { key: "SD", value: 211, text: "Sudan" },
    { key: "SR", value: 212, text: "Suriname" },
    { key: "SJ", value: 213, text: "Svalbard and Jan Mayen" },
    { key: "SZ", value: 214, text: "Swaziland" },
    { key: "SE", value: 215, text: "Sweden" },
    { key: "CH", value: 216, text: "Switzerland" },
    { key: "SY", value: 217, text: "Syrian Arab Republic" },
    { key: "TW", value: 218, text: "Taiwan, Province of China" },
    { key: "TJ", value: 219, text: "Tajikistan" },
    { key: "TZ", value: 220, text: "Tanzania, United Republic of" },
    { key: "TH", value: 221, text: "Thailand" },
    { key: "TL", value: 222, text: "Timor-Leste" },
    { key: "TG", value: 223, text: "Togo" },
    { key: "TK", value: 224, text: "Tokelau" },
    { key: "TO", value: 225, text: "Tonga" },
    { key: "TT", value: 226, text: "Trinidad and Tobago" },
    { key: "TN", value: 227, text: "Tunisia" },
    { key: "TR", value: 228, text: "Turkey" },
    { key: "TM", value: 229, text: "Turkmenistan" },
    { key: "TC", value: 230, text: "Turks and Caicos Islands" },
    { key: "TV", value: 231, text: "Tuvalu" },
    { key: "UG", value: 232, text: "Uganda" },
    { key: "UA", value: 233, text: "Ukraine" },
    { key: "AE", value: 234, text: "United Arab Emirates" },
    { key: "GB", value: 235, text: "United Kingdom" },
    { key: "UM", value: 236, text: "United States Minor Outlying Islands" },
    { key: "UY", value: 237, text: "Uruguay" },
    { key: "UZ", value: 238, text: "Uzbekistan" },
    { key: "VU", value: 239, text: "Vanuatu" },
    { key: "VE", value: 240, text: "Venezuela, Bolivarian Republic of" },
    { key: "VN", value: 241, text: "Viet Nam" },
    { key: "VG", value: 242, text: "Virgin Islands, British" },
    { key: "VI", value: 243, text: "Virgin Islands, U.S." },
    { key: "WF", value: 244, text: "Wallis and Futuna" },
    { key: "EH", value: 245, text: "Western Sahara" },
    { key: "YE", value: 246, text: "Yemen" },
    { key: "ZM", value: 247, text: "Zambia" },
    { key: "ZW", value: 248, text: "Zimbabwe" }
];

export const StateDdlList: DropdownItemProps[] = [
    { key: "AL", value: 1, text: "Alabama" },
    { key: "AK", value: 2, text: "Alaska" },
    { key: "AZ", value: 3, text: "Arizona" },
    { key: "AR", value: 4, text: "Arkansas" },
    { key: "CA", value: 5, text: "California" },
    { key: "CO", value: 6, text: "Colorado" },
    { key: "CT", value: 7, text: "Connecticut" },
    { key: "DE", value: 8, text: "Delaware" },
    { key: "FL", value: 9, text: "Florida" },
    { key: "GA", value: 10, text: "Georgia" },
    { key: "HI", value: 11, text: "Hawaii" },
    { key: "ID", value: 12, text: "Idaho" },
    { key: "IL", value: 13, text: "Illinois" },
    { key: "IN", value: 14, text: "Indiana" },
    { key: "IA", value: 15, text: "Iowa" },
    { key: "KS", value: 16, text: "Kansas" },
    { key: "KY", value: 17, text: "Kentucky" },
    { key: "LA", value: 18, text: "Louisiana" },
    { key: "ME", value: 19, text: "Maine" },
    { key: "MD", value: 20, text: "Maryland" },
    { key: "MA", value: 21, text: "Massachusetts" },
    { key: "MI", value: 22, text: "Michigan" },
    { key: "MN", value: 23, text: "Minnesota" },
    { key: "MS", value: 24, text: "Mississippi" },
    { key: "MO", value: 25, text: "Missouri" },
    { key: "MT", value: 26, text: "Montana" },
    { key: "NE", value: 27, text: "Nebraska" },
    { key: "NV", value: 28, text: "Nevada" },
    { key: "NH", value: 29, text: "New Hampshire" },
    { key: "NJ", value: 30, text: "New Jersey" },
    { key: "NM", value: 31, text: "New Mexico" },
    { key: "NY", value: 32, text: "New York" },
    { key: "NC", value: 33, text: "North Carolina" },
    { key: "ND", value: 34, text: "North Dakota" },
    { key: "OH", value: 35, text: "Ohio" },
    { key: "OK", value: 36, text: "Oklahoma" },
    { key: "OR", value: 37, text: "Oregon" },
    { key: "PA", value: 38, text: "Pennsylvania" },
    { key: "RI", value: 39, text: "Rhode Island" },
    { key: "SC", value: 40, text: "South Carolina" },
    { key: "SD", value: 41, text: "South Dakota" },
    { key: "TN", value: 42, text: "Tennessee" },
    { key: "TX", value: 43, text: "Texas" },
    { key: "UT", value: 44, text: "Utah" },
    { key: "VT", value: 45, text: "Vermont" },
    { key: "VA", value: 46, text: "Virginia" },
    { key: "WA", value: 47, text: "Washington" },
    { key: "WV", value: 48, text: "West Virginia" },
    { key: "WI", value: 49, text: "Wisconsin" },
    { key: "WY", value: 50, text: "Wyoming" },
    { key: "DC", value: 51, text: "District Of Columbia" },
    { key: "AS", value: 52, text: "American Samoa" },
    { key: "GU", value: 53, text: "Guam" },
    { key: "MP", value: 54, text: "Northern Mariana Islands" },
    { key: "PR", value: 55, text: "Puerto Rico" },
    { key: "VI", value: 56, text: "U.S. Virgin Islands" }
];

export const ProvinceDdlList: DropdownItemProps[] = [
    { key: "AB", value: 57, text: "Alberta" },
    { key: "BC", value: 58, text: "British Columbia" },
    { key: "MB", value: 59, text: "Manitoba" },
    { key: "NB", value: 60, text: "New Brunswick" },
    { key: "NL", value: 61, text: "Newfoundland and Labrador" },
    { key: "NS", value: 62, text: "Nova Scotia" },
    { key: "ON", value: 63, text: "Ontario" },
    { key: "PE", value: 64, text: "Prince Edward Island" },
    { key: "QC", value: 65, text: "Quebec" },
    { key: "SK", value: 66, text: "Saskatchewan" }
];

export const CompensationTypes = {
    Doctor: 5,
    Underwriter: 6
};
