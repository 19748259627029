import { Switchboard } from "@itm21st/switchboard";
import config from "../config";
import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";

const useSwitchboard = (): Switchboard => {
    const { getAccessTokenSilently } = useAuth0();
    const [switchboard] = useState<Switchboard>(() => {
        return new Switchboard(config.cloudApiBaseUrl, {
            path: "/switchboard/io",
            auth: async (cb: (obj: Record<string, unknown>) => void) => {
                const token = await getAccessTokenSilently();
                cb({ token });
            }
        });
    });
    return switchboard;
};

export default useSwitchboard;
