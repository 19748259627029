import envConfigDev from "./config.dev.json";
import envConfigProd from "./config.prod.json";
import envConfigUat from "./config.uat.json";
import rootConfig from "./config.json";

interface Config {
    authApiUrl: string;
    calculationApiUrl: string;
    cloudApiBaseUrl: string;
    databrokerApiUrl: string;
    emailApiUrl: string;
    fasanoClientWebUrl: string;
    fasanoInternalUrl: string;
    /** Auth0 Application (client) ID */
    fastClientID: string;
    fileApiUrl: string;
    helpEmail: string;
    idleTimeout: number;
    intacctApiUrl: string;
    leReportApiUrl: string;
    noReplyEmail: string;
    servicingInternalUrl: string;
    uwInternalUrl: string;
    auth0: {
        audience: string;
        clientId: string;
        domain: string;
    };
}

const env: "dev" | "uat" | "prod" = import.meta.env.VITE_APP_ENVIRONMENT || "dev";
const envMap = {
    dev: envConfigDev,
    uat: envConfigUat,
    prod: envConfigProd
};

const envConfig: Config = envMap[env] as Config;

const config: Config = {
    ...rootConfig,
    ...envConfig
};

export default config;
