import React, { createContext } from "react";
import { Role, Underwriter } from "../types/types";

import { useAuth0 } from "@auth0/auth0-react";
import { useCallback } from "react";
import useDatabroker from "../hooks/useDatabroker";
import { useEffect } from "react";
import { useState } from "react";

interface AppContextProps {
    /** aspnet_User UserID */
    userID?: string | undefined;
    roles?: Role[] | undefined;
    canViewUWGuidelines: boolean;
    canViewLECalculator: boolean;
    userHasRole: (role: Role) => boolean;
    userHasAnyRole: (roles: Role[]) => boolean;
    isDataChanged: boolean;
    setIsDataChanged: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AppContext = createContext<AppContextProps>({
    userID: undefined,
    roles: undefined,
    canViewUWGuidelines: false,
    canViewLECalculator: false,
    userHasAnyRole: () => false,
    userHasRole: () => false,
    isDataChanged: false,
    setIsDataChanged: () => false
});

const AppContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [canViewUWGuidelines, setCanViewUWGuidelines] = useState<boolean>(false);
    const [canViewLECalculator, setCanViewLECalculator] = useState<boolean>(false);
    const [isDataChanged, setIsDataChanged] = useState<boolean>(false);
    const [roles, setRoles] = useState<Role[]>();
    const [userID, setUserID] = useState<string>();
    const { isAuthenticated, user } = useAuth0();
    const databroker = useDatabroker();

    useEffect(() => {
        (async () => {
            if (!isAuthenticated || !user) {
                setRoles(undefined);
                setUserID(undefined);
                return;
            }

            const { data, error } = await databroker.query<{ UserID: string; RoleName: Role }>({
                select: ["uir.UserID", "r.RoleName"],
                from: {
                    objectName: "Meddiag.dbo.aspnet_Roles",
                    as: "r"
                },
                join: [
                    {
                        from: {
                            objectName: "Meddiag.dbo.aspnet_UsersInRoles",
                            as: "uir"
                        },
                        type: "inner",
                        on: ["uir.RoleID", "r.RoleID"]
                    },
                    {
                        from: {
                            objectName: "Meddiag.dbo.aspnet_Users",
                            as: "u"
                        },
                        type: "inner",
                        on: ["u.UserID", "uir.UserID"]
                    }
                ],
                where: {
                    "u.AuthID": user.sub as string
                }
            });

            if (error || !data) {
                throw new Error(`No user roles found for user`);
            }

            const userID = data[0].UserID;
            const roles = data.map((d) => d.RoleName);
            setUserID(userID);
            setRoles(roles);
        })();
    }, [databroker, isAuthenticated, user]);

    useEffect(() => {
        (async () => {
            if (!userID) {
                return;
            }
            try {
                const { data, error } = await databroker.search<Underwriter>({
                    objectName: "Fasano.dbo.Underwriter",
                    where: {
                        UserID: userID
                    }
                });

                if (error) {
                    throw new Error(error || `No underwriters found with userID`);
                }

                if (!data) {
                    return {
                        CanViewLECalculator: false,
                        CanViewUWGuidelines: false
                    };
                }

                const [underwriter] = data;
                if (!underwriter) {
                    return;
                }
                setCanViewUWGuidelines(underwriter.CanViewGuidelines || false);
                setCanViewLECalculator(underwriter.CanViewLECalculator || false);
            } catch (error) {
                console.error(error);
            }
        })();
    }, [databroker, userID]);

    const userHasRole = useCallback(
        (role: string) => {
            if (!roles) {
                return false;
            }
            return roles.some((r) => r === role);
        },
        [roles]
    );
    const userHasAnyRole = useCallback(
        (rolesToCheck: string[]) => {
            if (!roles) {
                return false;
            }
            return roles.some((r) => rolesToCheck.includes(r)) ?? false;
        },
        [roles]
    );

    return (
        <AppContext.Provider
            value={{
                userID,
                roles,
                canViewLECalculator,
                canViewUWGuidelines,
                userHasAnyRole,
                userHasRole,
                isDataChanged,
                setIsDataChanged
            }}
        >
            {children}
        </AppContext.Provider>
    );
};

export default AppContextProvider;
