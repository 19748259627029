import { Header, Icon } from "semantic-ui-react";
import React, { ComponentType, useContext } from "react";

import { AppContext } from "./AppContextProvider";
import Fallback from "./Fallback";
import { Role } from "../types/types";
import { useAuth0 } from "@auth0/auth0-react";

export interface WithAuthorizationRequiredProps {
    allowIfHasRole?: Role;
    allowIfHasAnyRole?: Role[];
    disallowIfHasRole?: Role;
}

const withAuthorizationRequired = <P extends object>(
    Component: ComponentType<P>,
    options: WithAuthorizationRequiredProps
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
): React.FC<any> => {
    return function WithAuthorizationRequired(props: P) {
        const { isAuthenticated, user } = useAuth0();
        const context = useContext(AppContext);
        if (!isAuthenticated || !user) {
            return <Fallback />;
        }

        if (!context.roles) {
            return <Header icon={<Icon name="cog" loading />} content="Checking user permissions..." color="grey" />;
        }

        const { allowIfHasAnyRole, allowIfHasRole, disallowIfHasRole } = options;

        if (!!allowIfHasRole && !context.userHasRole(allowIfHasRole)) {
            return <Fallback missingRole={allowIfHasRole} />;
        }

        if (!!allowIfHasAnyRole && !context.userHasAnyRole(allowIfHasAnyRole)) {
            return <Fallback missingSomeRole={allowIfHasAnyRole} />;
        }

        if (!!disallowIfHasRole && context.userHasRole(disallowIfHasRole)) {
            return <Fallback restrictedRole={disallowIfHasRole} />;
        }
        return <Component {...props} />;
    };
};

export default withAuthorizationRequired;
