import "semantic-ui-css/semantic.min.css";
import "react-toastify/dist/ReactToastify.css";
import "./css/kendo.scss";
import "./css/styles.scss";

import { Navigate, Route, Routes } from "react-router-dom";
import React, { Suspense, lazy } from "react";

import AuthError from "./components/AuthError";
import Fallback from "./components/Fallback";
import Layout from "./components/Layout";
import PrivateRoute from "./components/PrivateRoute";
import { Role } from "./types/types";
import SimpleLoader from "./components/SimpleLoader";
import { useAuth0 } from "@auth0/auth0-react";

const AbbreviationManagement = lazy(() => import("./feature/abbreviation-management/AbbreviationManagement"));
const CaseDetailRoot = lazy(() => import("./feature/case-detail/CaseDetailRoot"));
const CaseQueue = lazy(() => import("./feature/case-queue/CaseQueue"));
const CaseSearch = lazy(() => import("./feature/case-search/CaseSearch"));
const CaseUnderwriting = lazy(() => import("./feature/case-underwriting/CaseUnderwriting"));
const Client = lazy(() => import("./feature/client/ClientRoot"));
const ClientBilling = lazy(() => import("./feature/client-billing/ClientBilling"));
const Dashboard = lazy(() => import("./feature/Dashboard/Dashboard"));
const PageViewer = lazy(() => import("./feature/page-viewer/PageViewer"));
const InsuredSearch = lazy(() => import("./feature/insured-search/InsuredSearch"));
const InsuredDetail = lazy(() => import("./feature/insured/InsuredDetail"));
const LeCalculator = lazy(() => import("./feature/le-calculator/LeCalculator"));
const MessageInbox = lazy(() => import("./feature/message-inbox/MessageInbox"));
const MyCompensation = lazy(() => import("./feature/my-compensation/MyCompensation"));
const PhysicalCopyQueue = lazy(() => import("./feature/physical-copy-queue/PhysicalCopyQueue"));
const ReviewerAdmin = lazy(() => import("./feature/reviewer-admin/ReviewerAdmin"));
const ReportDashboard = lazy(() => import("./feature/report-dashboard/ReportDashboard"));
const ConditionManagement = lazy(() => import("./feature/condition-management/ConditionManagement"));
const SubmitQueue = lazy(() => import("./feature/submit-queue/SubmitQueue"));
const Timesheet = lazy(() => import("./feature/Timesheet/Timesheet"));
const TimesheetApproval = lazy(() => import("./feature/Timesheet/TimesheetApproval"));
const UnderwritingGuidelines = lazy(() => import("./feature/underwriting-guidelines/UnderwritingGuidelines"));
const UserDetails = lazy(() => import("./feature/user/User"));
const UserPermissions = lazy(() => import("./feature/user-permissions/UserPermissions"));
const UserSearch = lazy(() => import("./feature/user-search/UserSearch"));
const UWAdmin = lazy(() => import("./feature/uw-admin/UWAdmin"));
const UWReviewer = lazy(() => import("./feature/underwriter-reviewer-routing/UnderwriterReviewerRouting"));

const App: React.FC = () => {
    const { isLoading, error } = useAuth0();
    if (isLoading) {
        return <SimpleLoader />;
    }

    if (error) {
        return <AuthError error={error} />;
    }

    return (
        <Suspense fallback={<SimpleLoader />}>
            <Routes>
                {/* Public routes */}
                <Route path="/fallback" element={<Layout />}>
                    <Route path="" element={<Fallback />} />
                </Route>

                {/* Authenticated routes */}
                <Route path="/" element={<PrivateRoute component={Layout} />}>
                    <Route
                        path="abbreviation-management"
                        element={
                            <PrivateRoute
                                component={AbbreviationManagement}
                                allowIfHasRole={Role.Admin}
                                disallowIfHasRole={Role.Readonly}
                            />
                        }
                    />
                    <Route
                        path="case-search"
                        element={<PrivateRoute component={CaseSearch} allowIfHasRole={Role.FasanoUser} disallowIfHasRole={Role.Readonly} />}
                    />
                    <Route path="client-billing" element={<PrivateRoute component={ClientBilling} allowIfHasRole={Role.Admin} />} />
                    <Route
                        path="case-queue"
                        element={<PrivateRoute component={CaseQueue} allowIfHasAnyRole={[Role.Admin, Role.Processor]} />}
                    />
                    <Route
                        path="case-detail/:caseID"
                        element={
                            <PrivateRoute component={CaseDetailRoot} allowIfHasRole={Role.FasanoUser} disallowIfHasRole={Role.Readonly} />
                        }
                    />
                    <Route path="client">
                        <Route path="" element={<PrivateRoute component={Client} allowIfHasRole={Role.Admin} />} />
                        <Route path=":clientID" element={<PrivateRoute component={Client} allowIfHasRole={Role.Admin} />} />
                    </Route>
                    <Route path="case-underwriting">
                        <Route
                            path=""
                            element={
                                <PrivateRoute
                                    component={CaseUnderwriting}
                                    allowIfHasRole={Role.FasanoUser}
                                    disallowIfHasRole={Role.Readonly}
                                />
                            }
                        />
                        <Route
                            path=":caseID"
                            element={
                                <PrivateRoute
                                    component={CaseUnderwriting}
                                    allowIfHasRole={Role.FasanoUser}
                                    disallowIfHasRole={Role.Readonly}
                                />
                            }
                        />
                    </Route>
                    <Route
                        path="condition-management"
                        element={<PrivateRoute component={ConditionManagement} allowIfHasRole={Role.Admin} />}
                    />
                    <Route path="dashboard" element={<PrivateRoute component={Dashboard} allowIfHasRole={Role.Admin} />} />
                    <Route path="insured">
                        <Route path="" element={<PrivateRoute component={InsuredDetail} allowIfHasRole={Role.FasanoUser} />} />
                        <Route path=":insuredID" element={<PrivateRoute component={InsuredDetail} allowIfHasRole={Role.FasanoUser} />} />
                    </Route>
                    <Route path="insured-search" element={<PrivateRoute component={InsuredSearch} allowIfHasRole={Role.FasanoUser} />} />
                    <Route path="le-calculator" element={<PrivateRoute component={LeCalculator} allowIfHasRole={Role.FasanoUser} />} />
                    <Route
                        path="message-inbox"
                        element={<PrivateRoute component={MessageInbox} allowIfHasAnyRole={[Role.Admin, Role.Processor]} />}
                    />
                    <Route
                        path="my-caseload"
                        element={<PrivateRoute component={CaseQueue} allowIfHasRole={Role.FasanoUser} disallowIfHasRole={Role.Readonly} />}
                    />
                    <Route
                        path="my-compensation"
                        element={
                            <PrivateRoute component={MyCompensation} allowIfHasRole={Role.FasanoUser} disallowIfHasRole={Role.Readonly} />
                        }
                    />
                    <Route
                        path="my-timesheet"
                        element={<PrivateRoute component={Timesheet} allowIfHasRole={Role.FasanoUser} disallowIfHasRole={Role.Readonly} />}
                    />
                    <Route path="page-viewer/:caseID">
                        <Route path="" element={<PrivateRoute component={PageViewer} allowIfHasRole={Role.FasanoUser} />} />
                        <Route path=":filePageID" element={<PrivateRoute component={PageViewer} allowIfHasRole={Role.FasanoUser} />} />
                    </Route>
                    <Route
                        path="physical-copy-queue"
                        element={<PrivateRoute component={PhysicalCopyQueue} allowIfHasAnyRole={[Role.Processor, Role.Admin]} />}
                    />
                    <Route path="reviewer-admin" element={<PrivateRoute component={ReviewerAdmin} allowIfHasRole={Role.Admin} />} />
                    <Route path="report-dashboard" element={<PrivateRoute component={ReportDashboard} allowIfHasRole={Role.Admin} />} />
                    <Route path="submit-queue">
                        <Route
                            path=""
                            element={<PrivateRoute component={SubmitQueue} allowIfHasAnyRole={[Role.Processor, Role.Admin]} />}
                        />
                        <Route
                            path=":submitID"
                            element={<PrivateRoute component={SubmitQueue} allowIfHasAnyRole={[Role.Processor, Role.Admin]} />}
                        />
                    </Route>
                    <Route
                        path="timesheet-approval"
                        element={<PrivateRoute component={TimesheetApproval} allowIfHasRole={Role.UserAdministrator} />}
                    />
                    <Route path="user" element={<PrivateRoute component={UserSearch} allowIfHasRole={Role.Admin} />} />
                    <Route path="user/:authID" element={<PrivateRoute component={UserDetails} allowIfHasRole={Role.Admin} />} />
                    <Route
                        path="user-permissions"
                        element={<PrivateRoute component={UserPermissions} allowIfHasRole={Role.UserAdministrator} />}
                    />
                    <Route path="uw-admin" element={<PrivateRoute component={UWAdmin} allowIfHasRole={Role.Admin} />} />
                    <Route
                        path="underwriting-guidelines/:fileName"
                        element={<PrivateRoute component={UnderwritingGuidelines} allowIfHasAnyRole={[Role.Underwriter, Role.Admin]} />}
                    />
                    <Route
                        path="underwriter-reviewer-routing"
                        element={<PrivateRoute component={UWReviewer} allowIfHasRole={Role.Admin} />}
                    />
                    <Route path="/" element={<Navigate to="/my-caseload" replace />} />
                    <Route path="*" element={<Navigate to="/my-caseload" replace />} />
                </Route>
            </Routes>
        </Suspense>
    );
};

export default App;
