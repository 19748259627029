import { Dimmer, Loader } from "semantic-ui-react";

import React from "react";

interface SimpleLoaderProps {
    message?: string;
}
const SimpleLoader: React.FC<SimpleLoaderProps> = (props: SimpleLoaderProps) => {
    const { message } = props;
    return (
        <Dimmer active inverted inline="centered">
            <Loader inline>{message || "Loading..."}</Loader>
        </Dimmer>
    );
};

export default SimpleLoader;
