import { Dropdown } from "semantic-ui-react";
import React from "react";
import { fileType } from "../underwriting-guidelines/UnderwritingGuidelines";

const underwritingGuidelinesFiles: fileType[] = [
    "Structured Settlement",
    "Life Settlement",
    "Alzheimer's",
    "Respiratory",
    "Spinal Cord Injury",
    "HIV Guidelines",
    "Dementia",
    "Cerebral Palsy",
    "TBI Guidelines"
];

interface underwritingGuidelinesProps {
    location?: string;
}

const UnderwritingGuidelinesDropdown: React.FC<underwritingGuidelinesProps> = ({ location }: underwritingGuidelinesProps) => {
    // semantic UI react does not currently support nested dropdowns, so left/right shift on menuStyle required to display properly
    const dropdownStyle = location === "navbar" ? { marginLeft: "16px" } : { float: "right", marginRight: "15px" };
    const menuStyle = location === "navbar" ? { left: "-220px", right: "165px" } : null;
    return (
        <>
            <Dropdown
                item
                style={{ ...dropdownStyle, height: 31, lineHeight: 1.2, fontSize: 12 }}
                text="View Guidelines"
                icon="folder"
                labeled
                floating
                className="icon"
                button
                basic
            >
                <Dropdown.Menu style={menuStyle}>
                    <>
                        {underwritingGuidelinesFiles &&
                            underwritingGuidelinesFiles.map((fileName) => {
                                return (
                                    <Dropdown.Item key={`file${fileName}`}>
                                        <a href={`/underwriting-guidelines/${fileName}`} target="_blank" rel="noopener noreferrer">
                                            {fileName}
                                        </a>
                                    </Dropdown.Item>
                                );
                            })}
                    </>
                </Dropdown.Menu>
            </Dropdown>
        </>
    );
};

export default UnderwritingGuidelinesDropdown;
