import { Button, Grid, Icon, Message } from "semantic-ui-react";

import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

interface AuthErrorProps {
    error: Error;
}

const AuthError: React.FC<AuthErrorProps> = ({ error }) => {
    const { loginWithRedirect, logout } = useAuth0();

    return (
        <>
            <Grid centered padded="vertically">
                <Grid.Column width="6" textAlign="center">
                    <Message icon error>
                        <Icon name="exclamation triangle" />
                        <Message.Content>
                            <Message.Header>Authentication Error</Message.Header>
                            <p style={{ whiteSpace: "pre-line" }}>{error.message}</p>
                            <Button type="button" color="grey" content="Try again to log in" onClick={loginWithRedirect} />
                            <Button type="button" basic color="orange" content="Log out and start over" onClick={() => logout()} />
                        </Message.Content>
                    </Message>
                </Grid.Column>
            </Grid>
        </>
    );
};
export default AuthError;
