import { CallQueueAction, nonUnderwritersCanViewLECalculator, submitQueueChannel } from "../services/Constants";
import { Dropdown, Icon, Image, Menu, SearchProps } from "semantic-ui-react";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Search, SearchResultData } from "semantic-ui-react";

import { AppContext } from "./AppContextProvider";
import { Role } from "../types/types";
import { Subscription } from "@itm21st/switchboard";
import UnderwritingGuidelinesDropdown from "../feature/case-underwriting/UnderwritingGuidelinesDropdown";
import config from "../config";
import { debounce } from "lodash";
import logo from "../img/fasano-primary-logo.png";
import { toast } from "react-toastify";
import { useAuth0 } from "@auth0/auth0-react";
import useDatabroker from "../hooks/useDatabroker";
import { useNavigate } from "react-router-dom";
import useSwitchboard from "../hooks/useSwitchboard";

interface SearchItem {
    title: string;
    value: string;
}

const Navbar: React.FC = () => {
    const { userHasRole, userHasAnyRole, canViewUWGuidelines, canViewLECalculator, isDataChanged } = useContext(AppContext);
    const { logout, user } = useAuth0();

    const [isUnderwriter, setIsUnderwriter] = useState<boolean>(false);
    const [isReportWriter, setIsReportWriter] = useState<boolean>(false);
    const [isSearchLoading, setIsSearchLoading] = useState<boolean>(false);
    const [searchResults, setSearchResults] = useState<SearchItem[] | undefined>([]);
    const [searchValueText, setSearchValueText] = useState<string>("");
    const [submitQueueCount, setSubmitQueueCount] = useState<number>(0);
    const databroker = useDatabroker();
    const [subscription, setSubscription] = useState<Subscription>();
    const switchboard = useSwitchboard();
    const navigate = useNavigate();

    const isReadonly = userHasRole(Role.Readonly);

    const getSubmitQueueCount = useCallback(async (): Promise<number> => {
        const { data, error } = await databroker.sproc<{ SubmitQueueCount: number }>({
            objectName: "Fasano.dbo.GetSubmitQueueCount"
        });

        if (error || !data) {
            throw new Error("failed to retrieve submit queue count");
        }
        const [{ SubmitQueueCount }] = data;
        return SubmitQueueCount;
    }, [databroker]);

    useEffect(() => {
        (async () => {
            if (userHasAnyRole([Role.Processor, Role.Admin])) {
                try {
                    const count = await getSubmitQueueCount();
                    setSubmitQueueCount(count);
                } catch (error) {
                    console.error(error);
                    toast.error("Encountered error while retrieving transmittal queue count");
                }
            }
        })();
    }, [getSubmitQueueCount, userHasAnyRole]);

    useEffect(() => {
        (async () => {
            if (!subscription) {
                return;
            }

            if (userHasAnyRole([Role.Processor, Role.Admin])) {
                switchboard.subscribe(subscription);

                switchboard.on(CallQueueAction.NewTransmittal, async () => {
                    try {
                        const count = await getSubmitQueueCount();
                        setSubmitQueueCount(count);
                    } catch (error) {
                        console.error(error);
                        toast.error("Transmittal count failed to update following creation of a new transmittal");
                    }
                });

                switchboard.on(CallQueueAction.TransmittalDiscontinued, async () => {
                    try {
                        const count = await getSubmitQueueCount();
                        setSubmitQueueCount(count);
                    } catch (error) {
                        console.error(error);
                        toast.error("Transmittal count failed to update following transmittal discontinuation");
                    }
                });

                switchboard.on(CallQueueAction.CaseCreated, async () => {
                    try {
                        const count = await getSubmitQueueCount();
                        setSubmitQueueCount(count);
                    } catch (error) {
                        console.error(error);
                        toast.error("Transmittal count failed to update following case creation");
                    }
                });
            }
        })();

        return () => {
            subscription && switchboard.unsubscribe(subscription);
        };
    }, [getSubmitQueueCount, switchboard, subscription, userHasAnyRole]);

    useEffect(() => {
        setSubscription({
            ...submitQueueChannel
        });
    }, []);

    useEffect(() => {
        if (userHasRole(Role.Underwriter) && !userHasAnyRole([Role.Admin, Role.Processor, Role.ReportWriter])) {
            setIsUnderwriter(true);
        }
    }, [user, setIsUnderwriter, userHasRole, userHasAnyRole]);

    useEffect(() => {
        if (userHasRole(Role.ReportWriter) && !userHasAnyRole([Role.Admin, Role.Processor, Role.Underwriter])) {
            setIsReportWriter(true);
        }
    }, [user, setIsReportWriter, userHasRole, userHasAnyRole]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleSearchChange = useCallback(
        debounce(async (searchValue: string) => {
            setIsSearchLoading(true);

            try {
                const { data } = await databroker.sproc<SearchItem>({
                    objectName: "Fasano.dbo.GetSearchResults",
                    parameters: {
                        SearchInput: searchValue
                    }
                });
                if (data) {
                    const searchResults: SearchItem[] = data.map((item: SearchItem) => {
                        return {
                            value: item.value,
                            title: item.title,
                            key: `${item.title}${Math.random()}`
                        };
                    });
                    setSearchResults(searchResults);
                } else {
                    setSearchResults([]);
                }
            } catch (error) {
                console.error(error);
            }

            setIsSearchLoading(false);
        }, 500),
        []
    );

    function handleResultSelect(_event: React.SyntheticEvent, data: SearchResultData) {
        const { result } = data;
        const clientID = result.value;
        handleNavigation(`/client/${clientID}`);
    }

    const callHandleSearchChange = useCallback(
        async (event: React.SyntheticEvent, data: SearchProps) => {
            const { value } = data;

            setSearchValueText(value || "");
            if (value && value.length !== 0) {
                await handleSearchChange(value);
            }
        },
        [handleSearchChange]
    );

    // return window warning if timesheet data is not saved when navigating to new route
    // hrefs do not need to be handled here, niether does the logout function
    const handleNavigation = (to: string) => {
        if (isDataChanged) {
            const confirmationMessage = "Are you sure you want to leave this page? Your changes may not be saved.";
            const confirmNavigation = window.confirm(confirmationMessage);
            if (!confirmNavigation) {
                return;
            }
        }

        navigate(to);
    };

    return (
        <Menu fluid>
            <Menu.Menu position="left">
                <Menu.Item
                    header
                    content={<Image src={logo} style={{ height: "4rem" }} />}
                    position="left"
                    className="py-0"
                    onClick={() => handleNavigation("/")}
                />

                {(userHasRole(Role.ViewCaseSearch) || !isUnderwriter) && (
                    <Dropdown item text="Search">
                        <Dropdown.Menu>
                            {!isReadonly && (
                                <Dropdown.Item onClick={() => handleNavigation("/case-search")}>
                                    <Icon name="search" className="fasano-red" />
                                    Case Search
                                </Dropdown.Item>
                            )}
                            <Dropdown.Item onClick={() => handleNavigation("/insured-search")}>
                                <Icon name="user" className="fasano-red" />
                                Insured Search
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                )}
                {!isUnderwriter && !isReportWriter && !isReadonly && (
                    <Dropdown.Item onClick={() => handleNavigation("/submit-queue")}>
                        <Icon name="check square outline" className="fasano-red" />
                        Transmittal Queue
                        <span className="fasano-red" style={{ alignSelf: "end", fontSize: "0.8rem", fontWeight: "bold" }}>
                            {!!submitQueueCount && `(${submitQueueCount})`}
                        </span>
                    </Dropdown.Item>
                )}
                {!isReadonly && userHasRole(Role.Processor) && (
                    <Dropdown.Item as="a" href={`${config.uwInternalUrl}/MedRecHandlingQueue`} rel="noopener noreferrer" target="_blank">
                        <Icon name="file alternate outline" className="fasano-red" />
                        Med Rec Handling Queue
                    </Dropdown.Item>
                )}
                {!isUnderwriter && !isReadonly && (
                    <>
                        <Dropdown.Item onClick={() => handleNavigation("/case-queue")}>
                            <Icon name="list ul" className="fasano-red" />
                            Case Queue
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => handleNavigation("/physical-copy-queue")}>
                            <Icon name="list ul" className="fasano-red" />
                            Physical Copy Queue
                        </Dropdown.Item>
                    </>
                )}
                {!isReadonly && (
                    <Dropdown.Item onClick={() => handleNavigation("/my-caseload")}>
                        <Icon name="cube" className="fasano-red" />
                        My Caseload
                    </Dropdown.Item>
                )}
                {!isReadonly && !userHasRole(Role.TwentyFirstLEUnderwriter) && (
                    <Dropdown.Item onClick={() => handleNavigation("/my-timesheet")}>
                        <Icon name="table" className="fasano-red" />
                        My Timesheet
                    </Dropdown.Item>
                )}
                {!isUnderwriter && !isReportWriter && !isReadonly && (
                    <Dropdown.Item onClick={() => handleNavigation("/message-inbox")}>
                        <Icon name="envelope" className="fasano-red" />
                        Message Inbox
                    </Dropdown.Item>
                )}
                {(userHasRole(Role.Admin) ||
                    canViewLECalculator ||
                    (user && nonUnderwritersCanViewLECalculator.includes(user?.username))) && (
                    <Dropdown.Item onClick={() => handleNavigation("/le-calculator")}>
                        <Icon name="calculator" className="fasano-red" />
                        LE Calculator
                    </Dropdown.Item>
                )}
                {userHasRole(Role.Admin) && (
                    <span style={{ lineHeight: "56px", marginLeft: "20px" }}>
                        <Search
                            loading={isSearchLoading}
                            onResultSelect={handleResultSelect}
                            onSearchChange={callHandleSearchChange}
                            results={searchResults}
                            value={searchValueText}
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            onFocus={(event: any) => event.target.select()}
                            placeholder="Search client name, ID or email . . ."
                        />
                    </span>
                )}
            </Menu.Menu>
            {user && (
                <Menu.Menu position="right">
                    {userHasRole(Role.Admin) && (
                        <Dropdown item text="Administration">
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => handleNavigation("/abbreviation-management")}>
                                    <Icon name="hashtag" className="fasano-red" />
                                    Abbreviations
                                </Dropdown.Item>
                                <Dropdown.Item href={`${config.uwInternalUrl}/AnalyticsDashboard`}>
                                    <Icon name="dashboard" className="fasano-red" />
                                    Analytics Dashboard
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => handleNavigation("/client-billing")}>
                                    <Icon name="dollar sign" className="fasano-red" />
                                    Billing
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => handleNavigation("/client")}>
                                    <Icon name="volleyball ball" className="fasano-red" />
                                    Clients
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => handleNavigation("/condition-management")}>
                                    <Icon name="medkit" className="fasano-red" />
                                    Conditions
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => handleNavigation("/dashboard")}>
                                    <Icon name="dashboard" className="fasano-red" />
                                    FAST Dashboard
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => handleNavigation("/report-dashboard")}>
                                    <Icon name="chart bar" className="fasano-red" />
                                    Report Generator
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => handleNavigation("/reviewer-admin")}>
                                    <Icon name="eye" className="fasano-red" />
                                    Reviewers
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => handleNavigation("/underwriter-reviewer-routing")}>
                                    <Icon name="sync" className="fasano-red" />
                                    Reviewer Routing
                                </Dropdown.Item>
                                <Dropdown.Item href={`${config.uwInternalUrl}/SharedDashboard`}>
                                    <Icon name="dashboard" className="fasano-red" />
                                    Shared Dashboard
                                </Dropdown.Item>
                                {userHasRole(Role.UserAdministrator) && (
                                    <Dropdown.Item onClick={() => handleNavigation("/timesheet-approval")}>
                                        <Icon name="table" className="fasano-red" />
                                        Timesheet Approval
                                    </Dropdown.Item>
                                )}
                                {userHasRole(Role.UserAdministrator) && (
                                    <Dropdown.Item onClick={() => handleNavigation("/user-permissions")}>
                                        <Icon name="lock" className="fasano-red" />
                                        User Permissions
                                    </Dropdown.Item>
                                )}
                                <Dropdown.Item onClick={() => handleNavigation("/user")}>
                                    <Icon name="users" className="fasano-red" />
                                    Client Users
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => handleNavigation("/uw-admin")}>
                                    <Icon name="doctor" className="fasano-red" />
                                    Underwriters
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    )}
                    <Dropdown item text={user?.nickname?.toLowerCase()}>
                        <Dropdown.Menu>
                            {(canViewUWGuidelines || userHasRole(Role.Admin)) && <UnderwritingGuidelinesDropdown location="navbar" />}
                            {!userHasRole(Role.TwentyFirstLEUnderwriter) && !isReadonly && (
                                <Dropdown.Item onClick={() => handleNavigation("/my-compensation")}>
                                    <Icon name="money" />
                                    My Compensation
                                </Dropdown.Item>
                            )}
                            <Dropdown.Item content="Log Out" icon="log out" onClick={() => logout()} />
                        </Dropdown.Menu>
                    </Dropdown>
                </Menu.Menu>
            )}
        </Menu>
    );
};

export default Navbar;
