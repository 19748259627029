import Databroker from "@itm21st/databroker";
import config from "../config";
import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";

const baseURL = config.databrokerApiUrl;

const useDatabroker = (): Databroker => {
    const { getAccessTokenSilently } = useAuth0();
    const [databroker] = useState<Databroker>(() => {
        return new Databroker({
            baseURL,
            token: getAccessTokenSilently
        });
    });
    return databroker;
};

export default useDatabroker;
